import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import './Site.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import useAxiosFunction from "../../hook/AxiosHook";
import {setSite, updateSite} from "../../reducer/SiteReducer";
import SiteForm from "./SiteForm";
import SiteList from "./SiteList";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {showMasterDateOption} from "../../reducer/HideMasterDropdownReducer";
import SiteExportModal from "./SiteExportModal";
import {setAllSite, updateAllSite} from "../../reducer/SiteAllReducer";
import Loader from "../../commom/LoaderComponent";
import {Link} from "react-router-dom";
import {updateIdn} from "../../reducer/IdnReducer";
import {useOuterClick} from "../../hook/VisibilityHook";
import {
    getArrayData,
    getFilteredArray, getFirstArrayValue,
    getPlantMasterData, getSelectedAllFlag,
    getSelectedPlantConfigArray,
    getUserAccessToUrl, trimObjectValues,
    updatedArrayByActiveKey, updatedObjectByActiveKey,
    updateMultiSelectOption,
    updateMultiSelectSearch
} from "../../commom/CommonFunction";
import Message from "../../commom/Message";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setPlant} from "../../reducer/PlantReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import ConfirmationModal from "../../commom/ConfirmationModal";

const Site = () => {
    const messageValue = "You have changed the facility Name. It will reflect only for IDN and SAM. All the past data entered for the facility will remain unchanged. Do you want to submit the data?";
    const screen = useSelector((state) => state.plantDropdownReducer.screen);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [dvpList, setDvpList] = useState();
    const [rdoList, setRdoList] = useState();
    const [gmList, setGmList] = useState();
    const [visitFrequencyList, setVisitFrequencyList] = useState();
    const [traumaLevelList, setTraumaLevelList] = useState();
    const [samList, setSamList] = useState();
    const [idnList, setIdnList] = useState();
    const [idnUpdatedList, setIdnUpdatedList] = useState();
    const [oldIdn, setOldIdn] = useState("");
    const [oldPlant, setOldPlant] = useState("");
    const [formValue, setFormValue] = useState({
        _id: "",
        plant: "",
        plantHc: "",
        site: "",
        siteId: "",
        siteStatus: "",
        dateStarted: "",
        division: "",
        region: "",
        sam: "",
        annualVolume: "",
        idnAffiliation: "",
        visitFrequency: "",
        requiredCV: "",
        requiredCS: "",
        requiredEU: "",
        requiredQA: "",
        requiredPAR: "",
        requiredLL: "",
        LArequiredCommittee: "",
        LCrequiredCommittee: "",
        requiredQBRs: "",
        addOnSales: "",
        dvp: "",
        rm: "",
        gm: "",
        volume: "",
        traumaLevel: "",
        updatedBy: "",
        contactName1: "",
        contactEmail1: "",
        contactPhone1: "",
        contactName2: "",
        contactEmail2: "",
        contactPhone2: "",
        contactName3: "",
        contactEmail3: "",
        contactPhone3: "",
        contactName4: "",
        contactEmail4: "",
        contactPhone4: "",
        contactName5: "",
        contactEmail5: "",
        contactPhone5: "",
        showIdn: false,
        selectAllIdn: false,
        idnCount: 0,
    });
    const [inputValidation, setInputValidation] = useState({
        plant: true,
        plantHc: true,
        site: true,
        siteId: true,
        division: true,
        region: true,
        sam: true,
        visitFrequency: true,
        requiredCV: true,
        requiredCS: true,
        requiredEU: true,
        requiredQA: true,
        requiredPAR: true,
        requiredLL: true,
        LArequiredCommittee: true,
        LCrequiredCommittee: true,
        requiredQBRs: true,
        addOnSales: true,
        dvp: true,
        rm: true,
        gm: true,
    });
    const fieldValidation = {
        plant: "select",
        site: "string",
        siteId: "string",
        siteStatus: "select",
        division: "string",
        region: "string",
        sam: "select",
        annualVolume: "string",
        visitFrequency: "select",
        requiredCV: "number",
        requiredCS: "number",
        requiredEU: "number",
        requiredQA: "number",
        requiredPAR: "number",
        requiredLL: "number",
        LArequiredCommittee: "number",
        LCrequiredCommittee: "number",
        requiredQBRs: "number",
        addOnSales: "number",
        dvp: "select",
        rm: "select",
        gm: "select"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [showSiteModal, setShowSiteModal] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [readOnlyField, setReadOnlyField] = useState(false);
    const [idnNewList, setIdnNewList] = useState();
    const innerIdnRef = useOuterClick(() => {
        setFormValue({...formValue, showIdn: false});
    });
    const [frequencyData, setFrequencyData] = useState();
    const [plantData, setPlantData] = useState();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [isAdminAccess, setIsAdminAccess] = useState(false);
    const [selectedSite, setSelectedSite] = useState("");
    const [messageData, setMessageData] = useState("");
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(true);
    const allowSubmitRef = useRef(allowSubmit);

    useEffect(() => {
        dispatch(showDateInHeader(false));
        dispatch(setDateRange());

        getUserDetailWithAllSite().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(setUserRole(response.securityRoles));
            dispatch(userDetail({"user": response._doc}));
            if(response._doc.securityRole === "Admin") {
                setIsAdminAccess(true);
            }

            let updatedArray = updatedArrayByActiveKey(response.allSites);
            dispatch(setSite(response.sites));
            dispatch(setAllSite(updatedArray));
            dispatch(setPlant(response.plants));
            setPlantData(response.plants);
            setFrequencyData(response.masterData);
            setSamList(getSelectedPlantConfigArray("Sam", "type", response.masterData, "value"));
            setVisitFrequencyList(getSelectedPlantConfigArray("Visit Frequency", "type", response.masterData, "value"));
            setTraumaLevelList(getSelectedPlantConfigArray("Trauma Level", "type", response.masterData, "value"));
            dispatch(showMasterDateOption(false));
        });
        getActiveIdnHaveDisplay().then((fetchedValue) => {
            let newIdnList = getFilteredArray(["SuperAdmin IDN", "Admin IDN"], fetchedValue, "idnAffiliation");
            setIdnList(fetchedValue);
            setIdnUpdatedList(newIdnList);
            setIdnNewList(updateMultiSelectOption(newIdnList, "idnAffiliation"))
        });
    }, []);

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithAllSite = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithAllSite',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const getActiveIdnHaveDisplay = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getActiveIdnHaveDisplay'
        });
        return await response.data;
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableSubmit(false);

        if (name === "idn") {
            setIdnNewList(newData);
            setFormValue({...formValue, selectAllIdn: checked, idnCount: count});
        }
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "idn") {
            let flag = formValue.showIdn;

            if(!flag) {
                let data = updateMultiSelectSearch(idnUpdatedList, "idnAffiliation", "", idnNewList);
                setIdnNewList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showIdn: !flag, selectAllIdn: selectedAll});
            } else {
                setFormValue({...formValue, showIdn: !flag});

            }
        }
    };

    const handleSearch = (event) => {
        const {value} = event.currentTarget;
        let data = updateMultiSelectSearch(idnUpdatedList, "idnAffiliation", value, idnNewList);
        setIdnNewList(data);
        let selectedAll = getSelectedAllFlag(data);
        setFormValue({...formValue, selectAllIdn: selectedAll});
    };

    const plantSelection = (plant, flag) => {
        if (!plant) {
            setDvpList([]);
            setRdoList([]);
            setGmList([]);
        } else {
            if (plantData && plantData.length) {
                let selectedPlant = plantData.find((obj) => {
                    if (obj._id === plant) {
                        return obj;
                    }
                });

                if(selectedPlant) {
                    let dvpArray = getPlantMasterData(frequencyData, "DVP", selectedPlant._id);
                    let rdoArray = getPlantMasterData(frequencyData, "RDO", selectedPlant._id);
                    let gmArray = getPlantMasterData(frequencyData, "GM", selectedPlant._id);
                    setDvpList(dvpArray);
                    setRdoList(rdoArray);
                    setGmList(gmArray);

                    if (flag) {
                        setFormValue((preState) => ({
                            ...preState,
                            plantHc: selectedPlant.houseCode,
                            division: selectedPlant.division,
                            region: selectedPlant.region,
                            street: selectedPlant.street,
                            city: selectedPlant.city,
                            state: selectedPlant.state,
                            zip: selectedPlant.zip,
                            dvp: getFirstArrayValue(dvpArray, "value"),
                            rm: getFirstArrayValue(rdoArray, "value"),
                            gm: getFirstArrayValue(gmArray, "value"),
                        }));
                    } else {
                        setFormValue((preState) => ({
                            ...preState,
                            plantHc: selectedPlant.houseCode
                        }));
                    }
                } else {
                    setDvpList([]);
                    setRdoList([]);
                    setGmList([]);
                }
            }
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        setMessageData("Please complete the *required field without using any of the following special characters: (){}[]\".");
    };

    const handleChange = (event,automation) => {
        console.log('CL00479');
        setShowMessage(false);
        setDisableSubmit(false);

        if (event.currentTarget.name === "plant") {
            const currentFormState = {...formValue};
            let selectedPlant, dvpArray, rdoArray, gmArray;
            if (plantData && plantData.length) {
                selectedPlant = plantData.find((obj) => {
                    if (obj._id === event.currentTarget.value) {
                        return obj;
                    }
                });
                dvpArray = getPlantMasterData(frequencyData, "DVP", selectedPlant._id);
                rdoArray = getPlantMasterData(frequencyData, "RDO", selectedPlant._id);
                gmArray = getPlantMasterData(frequencyData, "GM", selectedPlant._id);
                setDvpList(dvpArray);
                setRdoList(rdoArray);
                setGmList(gmArray);
            }

            let countValue = 0;
            if(idnUpdatedList && idnUpdatedList.length) {
                let matchedIdn = event.currentTarget.value + "PlantIDN";
                let removeIdn = formValue.plant + "PlantIDN";
                matchedIdn = matchedIdn.toLowerCase();
                removeIdn = removeIdn.toLowerCase();
                let matchedIdnObject = "";
                let removeIdnObject = "";
                idnUpdatedList.forEach((obj) => {
                    if ((obj.idnAffiliation).toLowerCase() ===  matchedIdn) {
                        matchedIdnObject = obj;
                    }
                    if ((obj.idnAffiliation).toLowerCase() ===  removeIdn) {
                        removeIdnObject = obj;
                    }
                });
                let flag = false;
                if(matchedIdnObject || removeIdnObject) {
                    idnNewList.forEach((obj) => {
                        if (matchedIdnObject && obj.label ===  matchedIdnObject.idnAffiliation) {
                            obj.value = true;
                            flag = true;
                            countValue += 1;
                        }
                        if (removeIdnObject && obj.label ===  removeIdnObject.idnAffiliation) {
                            obj.value = false;
                            countValue -= 1
                        }
                    });

                    if(!flag && matchedIdnObject) {
                        const newItem = { label: matchedIdnObject.idnAffiliation, value: true };
                        setIdnNewList((prevIdnNewList) => ([...prevIdnNewList, newItem]));
                        countValue += 1;
                    }
                }
            }

            if(selectedPlant) {
                currentFormState.plant = selectedPlant._id;
                currentFormState.plantHc = selectedPlant.houseCode;
                currentFormState.division = selectedPlant.division;
                currentFormState.region = selectedPlant.region;
                currentFormState.street = selectedPlant.street;
                currentFormState.city = selectedPlant.city;
                currentFormState.state = selectedPlant.state;
                currentFormState.zip = selectedPlant.zip;
                currentFormState.dvp = getFirstArrayValue(dvpArray, "value");
                currentFormState.rm = getFirstArrayValue(rdoArray, "value");
                currentFormState.gm = getFirstArrayValue(gmArray, "value");
            }

            if(countValue) {
                currentFormState.idnCount = parseInt(currentFormState.idnCount) + countValue;
            }
            setFormValue(currentFormState);
        } else {
            
            const currentFormState = handleInputChange(event, formValue);
            console.log('currentFormState',currentFormState)
            if(automation === '>3M'){
                console.log('<3M')
                currentFormState.requiredCV = 4;
                currentFormState.requiredCS = 1;
                currentFormState.requiredEU = 40;
                currentFormState.requiredQA = 20;         
                currentFormState.requiredPAR = 20;
                currentFormState.requiredLL = 2;
                currentFormState.LArequiredCommittee = 1;
                currentFormState.LCrequiredCommittee = 1;
                currentFormState.addOnSales = 1;
                currentFormState.visitFrequency ="monthly";
                currentFormState.requiredQBRs = 1;
                currentFormState.annualVolume = '>3M'
            }else if(automation === '1M - 3M'){
                console.log('3M-1M')
                currentFormState.requiredCV = 2;
                currentFormState.requiredCS = 1;
                currentFormState.requiredEU = 20;
                currentFormState.requiredQA = 20;         
                currentFormState.requiredPAR = 10;
                currentFormState.requiredLL = 2;
                currentFormState.LArequiredCommittee = 1;
                currentFormState.LCrequiredCommittee = 1;
                currentFormState.addOnSales = 2;
                currentFormState.visitFrequency ="monthly"; 
                currentFormState.requiredQBRs = 1;
                 currentFormState.annualVolume = '1M - 3M'

            }else if(automation === '500K - 999K' ){
                console.log('999K-500K')
                currentFormState.requiredCV = 1;
                currentFormState.requiredCS = 1;
                currentFormState.requiredEU = 10;
                currentFormState.requiredQA = 10;         
                currentFormState.requiredPAR = 5;
                currentFormState.requiredLL = 2;
                currentFormState.LArequiredCommittee = 1;
                currentFormState.LCrequiredCommittee = 1;
                currentFormState.addOnSales = 1;
                currentFormState.visitFrequency ="monthly";  
                currentFormState.requiredQBRs = 1;
                currentFormState.annualVolume = '500K - 999K'

            }else if(automation === '100K - 499K'){
                console.log( '499K-100K')
                currentFormState.requiredCV = 1;
                currentFormState.requiredCS = 1;
                currentFormState.requiredEU = 10;
                currentFormState.requiredQA = 10;         
                currentFormState.requiredPAR = 6;
                currentFormState.requiredLL = 2;
                currentFormState.LArequiredCommittee = 1;
                currentFormState.LCrequiredCommittee = 1;
                currentFormState.addOnSales = 1;
                currentFormState.visitFrequency ="monthly";
                currentFormState.requiredQBRs = 1;
                  currentFormState.annualVolume = '100K - 499K'
  
            }
            // console.log('currentFormState',currentFormState)
            setFormValue(currentFormState);
        }


        if (event.currentTarget.name === "site" && selectedSite) {
            setAllowSubmit(false);
            allowSubmitRef.current = false;
        }

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };


    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        if (flag) {
            setFormValue(currentFormState);
        } else {
            currentFormState.selectAllIdn = false;
            currentFormState.idnCount = 0;
            currentFormState.showIdn = false;
            setFormValue(currentFormState);
        }
    };

    const selectedIdnValue = (selectedData) => {
        selectedData["selectAllIdn"] = false;
        selectedData["idnCount"] = 0;
        selectedData["showIdn"] = false;
        let selectedIdns = selectedData.idnAffiliation;

        let newArray = [];
        let count = 0;
        idnNewList.forEach((obj) => {
            let dataObject = Object.assign({}, obj);
            let data = dataObject.label;

            let index = selectedIdns.indexOf(data);

            if (index !== -1) {
                newArray.push({
                    "label": data,
                    "value": true
                });
                count += 1;
            } else {
                newArray.push({
                    "label": data,
                    "value": false
                });
            }
        });
        selectedData["idnCount"] = count;

        setIdnNewList(newArray);
        setFormValue(selectedData);
    };

    const unselectedIdnValue = () => {
        let newArray = [];

        idnNewList.forEach((obj) => {
            let dataObject = Object.assign({}, obj);
            let data = dataObject.label;

            newArray.push({
                "label": data,
                "value": false
            });
        });

        setIdnNewList(newArray);
    };

    const selectedRow = (value) => {
        allowSubmitRef.current = true;
        setDisableSubmit(true);
        setReadOnlyField(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        plantSelection(value.plant, false);
        let newValue = Object.assign({}, value, {"selectAllIdn": false, "idnCount": 0, "showIdn": false});
        selectedIdnValue(newValue);
        setOldIdn(value.idnAffiliation);
        setOldPlant(value.plantHc);
        setSelectedSite(value.site);
        setMessageData("Please complete the *required field without using any of the following special characters: (){}[]\".");
    };

    const cancelHandler = () => {
        allowSubmitRef.current = true;
        setSelectedSite("");
        setDisableSubmit(false);
        setReadOnlyField(false);
        plantSelection("", false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setMessageData("Please complete the *required field without using any of the following special characters: (){}[]\".");
        setInputValidation(currentFormState);
        formInputValueSetup(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        unselectedIdnValue();
        setOldIdn("");
        setOldPlant("");
    };

    const handleIdnChange = (event) => {
        setDisableSubmit(false);
        let newIdn = event.currentTarget.value;
        if (formValue.idnAffiliation === newIdn) {
            return false;
        }
        setFormValue({...formValue, "idnAffiliation": newIdn});
    };

    const submitIdnHandler = async (data) => {
        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateIdn',
            requestConfig: {
                data: data
            }
        });

        if (response.data) {
            dispatch(updateIdn(response.data));
        }
        setShowMessage(true);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        let selectedIdnData = getArrayData(idnNewList);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        if(selectedSite && !allowSubmitRef.current) {
            setShowConfirmationBox(true);
            return false;
        }

        let isAdmin = false, isSuperAdmin = false;

        if(selectedIdnData && selectedIdnData.length) {
            selectedIdnData.forEach((selectedIdn) => {
                if (selectedIdn === "Admin IDN") {
                    isAdmin = true;
                }

                if (selectedIdn === "SuperAdmin IDN") {
                    isSuperAdmin = true;
                }
            });
        }

        if(!isAdmin) {
            selectedIdnData.push("Admin IDN")
        }
        if(!isSuperAdmin) {
            selectedIdnData.push("SuperAdmin IDN")
        }

        let newData = trimObjectValues(formValue);
        newData["idnAffiliation"] = selectedIdnData;
        let user = JSON.parse(localStorage.getItem('userDetail'));
        newData["updatedBy"] = user.userId;
        setFormValidation(true);

        console.log(newData);
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSite',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            setSelectedSite(response.data.site);
            let activeValue = updatedObjectByActiveKey(response.data);

            dispatch(updateSite(activeValue));
            dispatch(updateAllSite(activeValue));
            setButtonTitle("Update");
            setReadOnlyField(true);
            let oldIdnUpdate = [];
            let removedIdnUpdate = [];
            let newIdnUpdate = [];
            let oldPlantValue = oldPlant;
            setOldPlant("");

            if (formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }

            if (oldPlantValue !== "" && oldPlantValue !== formValue.plantHc) {
                if (oldIdn && oldIdn.length) {
                    oldIdn.forEach((idnValue) => {
                        let index = selectedIdnData.indexOf(idnValue);

                        if (index === -1) {
                            removedIdnUpdate.push(idnValue);
                        } else {
                            oldIdnUpdate.push(idnValue);
                        }

                    });

                    idnList.forEach((idnObj) => {
                        let removeIndex = removedIdnUpdate.indexOf(idnObj.idnAffiliation);
                        let isAdminIdn = idnObj.isAdmin;
                        let removePlantIndex = "";

                        if(removeIndex != -1){
                            console.log(removeIndex);
                        }

                        if (removeIndex !== -1 && !isAdminIdn) {
                            idnObj.plants.forEach((plantObj) => {

                                if (plantObj.id === oldPlantValue) {
                                    let subModule = plantObj.subModule;
                                    const findIndex = subModule.findIndex(siteObj => siteObj.id === formValue.siteId);
                                    if (findIndex !== -1) {
                                        subModule.splice(findIndex, 1)
                                    }
                                    if (subModule.length < 1) {
                                        removePlantIndex = plantObj.id;
                                    }
                                }
                            });

                            if (removePlantIndex) {
                                let plantIndex = (idnObj.plants).findIndex(a => a.id === removePlantIndex);
                                idnObj.plants.splice(plantIndex, 1);
                            }

                            submitIdnHandler(idnObj).then();
                        }

                        let flag = true;
                        let newIndex = selectedIdnData.indexOf(idnObj.idnAffiliation);

                        if (newIndex !== -1) {
                            idnObj.plants.forEach((plantNewObj) => {
                                if (plantNewObj.id === formValue.plantHc) {
                                    flag = false;
                                    let subNewModule = plantNewObj.subModule;
                                    const findIndex = subNewModule.findIndex(siteObj => siteObj.id === formValue.siteId);
                                    if (findIndex === -1) {
                                        subNewModule.push({
                                            "id": formValue.siteId,
                                            "name": formValue.site,
                                            "isActive": true
                                        })
                                    }
                                }

                                if (plantNewObj.id === oldPlantValue) {
                                    let subNewModule = plantNewObj.subModule;
                                    const findIndex = subNewModule.findIndex(siteObj => siteObj.id === formValue.siteId);
                                    if (findIndex !== -1) {
                                        subNewModule.splice(findIndex, 1);
                                    }
                                    if (subNewModule.length < 1) {
                                        removePlantIndex = plantNewObj.id;
                                    }

                                }
                            });

                            if (flag) {
                                let newPlantObj = {
                                    "id": formValue.plantHc,
                                    "name": formValue.plant,
                                    "isActive": true,
                                    "open": false,
                                    "subModule": [{
                                        "id": formValue.siteId,
                                        "name": formValue.site,
                                        "isActive": true
                                    }]
                                };
                                idnObj.plants.push(newPlantObj);
                            }

                            if (removePlantIndex) {
                                let plantIndex = (idnObj.plants).findIndex(a => a.id === removePlantIndex);
                                idnObj.plants.splice(plantIndex, 1);
                            }
                            submitIdnHandler(idnObj).then();
                        }
                    })
                } else {
                    idnList.forEach((idnObj) => {
                        let flag = true;
                        let newIndex = selectedIdnData.indexOf(idnObj.idnAffiliation);
                        let removePlantIndex = "";

                        if (newIndex !== -1) {
                            idnObj.plants.forEach((plantNewObj) => {
                                if (plantNewObj.id === formValue.plantHc) {
                                    flag = false;
                                    let subNewModule = plantNewObj.subModule;
                                    const findIndex = subNewModule.findIndex(siteObj => siteObj.id === formValue.siteId);
                                    if (findIndex === -1) {
                                        subNewModule.push({
                                            "id": formValue.siteId,
                                            "name": formValue.site,
                                            "isActive": true
                                        })
                                    }
                                }

                                if (plantNewObj.id === oldPlantValue) {
                                    let subNewModule = plantNewObj.subModule;
                                    const findIndex = subNewModule.findIndex(siteObj => siteObj.id === formValue.siteId);
                                    if (findIndex !== -1) {
                                        subNewModule.splice(findIndex, 1);
                                    }
                                    if (subNewModule.length < 1) {
                                        removePlantIndex = plantNewObj.id;
                                    }

                                }
                            });

                            if (flag) {
                                let newPlantObj = {
                                    "id": formValue.plantHc,
                                    "name": formValue.plant,
                                    "isActive": true,
                                    "open": false,
                                    "subModule": [{
                                        "id": formValue.siteId,
                                        "name": formValue.site,
                                        "isActive": true
                                    }]
                                };
                                idnObj.plants.push(newPlantObj);
                            }

                            if (removePlantIndex) {
                                let plantIndex = (idnObj.plants).findIndex(a => a.id === removePlantIndex);
                                idnObj.plants.splice(plantIndex, 1);
                            }

                            submitIdnHandler(idnObj).then();
                        }
                    })
                }
            } else {
                if (oldIdn && oldIdn.length) {
                    oldIdn.forEach((idnValue) => {
                        let index = selectedIdnData.indexOf(idnValue);

                        if (index === -1) {
                            removedIdnUpdate.push(idnValue);
                        } else {
                            oldIdnUpdate.push(idnValue);
                        }

                    });

                    selectedIdnData.forEach((idnValue) => {
                        let index = oldIdnUpdate.indexOf(idnValue);

                        if (index === -1) {
                            newIdnUpdate.push(idnValue);
                        }

                    });

                    idnList.forEach((idnObj) => {
                        let removeIndex = removedIdnUpdate.indexOf(idnObj.idnAffiliation);
                        let isAdminIdn = idnObj.isAdmin;
                        let removePlantIndex = "";

                        if (removeIndex !== -1 && !isAdminIdn) {
                            idnObj.plants.forEach((plantObj) => {

                                if (plantObj.id === formValue.plantHc) {
                                    let subModule = plantObj.subModule;
                                    const findIndex = subModule.findIndex(siteObj => siteObj.id === formValue.siteId);
                                    if (findIndex !== -1) {
                                        subModule.splice(findIndex, 1)
                                    }

                                    if (subModule.length < 1) {
                                        removePlantIndex = plantObj.id;
                                    }
                                }
                            });

                            if (removePlantIndex) {
                                let plantIndex = (idnObj.plants).findIndex(a => a.id === removePlantIndex);
                                idnObj.plants.splice(plantIndex, 1);
                            }

                            submitIdnHandler(idnObj).then();
                        }

                        let flag = true;
                        let newIndex = newIdnUpdate.indexOf(idnObj.idnAffiliation);

                        if (newIndex !== -1) {
                            idnObj.plants.forEach((plantNewObj) => {
                                if (plantNewObj.id === formValue.plantHc) {
                                    flag = false;
                                    let subNewModule = plantNewObj.subModule;
                                    const findIndex = subNewModule.findIndex(siteObj => siteObj.id === formValue.siteId);
                                    if (findIndex === -1) {
                                        subNewModule.push({
                                            "id": formValue.siteId,
                                            "name": formValue.site,
                                            "isActive": true
                                        })
                                    }
                                }
                            });

                            if (flag) {
                                let newPlantObj = {
                                    "id": formValue.plantHc,
                                    "name": formValue.plant,
                                    "isActive": true,
                                    "open": false,
                                    "subModule": [{
                                        "id": formValue.siteId,
                                        "name": formValue.site,
                                        "isActive": true
                                    }]
                                };
                                idnObj.plants.push(newPlantObj);
                            }
                            submitIdnHandler(idnObj).then();
                        }
                    })
                } else {
                    idnList.forEach((idnObj) => {
                        let flag = true;
                        let newIndex = selectedIdnData.indexOf(idnObj.idnAffiliation);

                        if (newIndex !== -1) {
                            idnObj.plants.forEach((plantNewObj) => {
                                if (plantNewObj.id === formValue.plantHc) {
                                    flag = false;
                                    let subNewModule = plantNewObj.subModule;
                                    const findIndex = subNewModule.findIndex(siteObj => siteObj.id === formValue.siteId);
                                    if (findIndex === -1) {
                                        subNewModule.push({
                                            "id": formValue.siteId,
                                            "name": formValue.site,
                                            "isActive": true
                                        })
                                    }
                                }
                            });

                            if (flag) {
                                let newPlantObj = {
                                    "id": formValue.plantHc,
                                    "name": formValue.plant,
                                    "isActive": true,
                                    "open": false,
                                    "subModule": [{
                                        "id": formValue.siteId,
                                        "name": formValue.site,
                                        "isActive": true
                                    }]
                                };
                                idnObj.plants.push(newPlantObj);
                            }

                            submitIdnHandler(idnObj).then();
                        }
                    })
                }
            }

            selectedRow(response.data);
        }
        setShowMessage(true);
    };

    const handleClose = () => {
        setShowSiteModal(false);
    };

    const showModal = () => {
        setShowSiteModal(true);
    };

    const handleConfirmBoxClose = () => {
        setShowConfirmationBox(false);
    };

    const submitChangeOnSite = (event) => {
        setAllowSubmit(true);
        allowSubmitRef.current = true;
        handleConfirmBoxClose();
        submitHandler(event);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>

            {showConfirmationBox && <ConfirmationModal handleClose={handleConfirmBoxClose} showModal={showConfirmationBox}
                                                       submitForm={submitChangeOnSite} messageValue={messageValue}/>}
            <Row>
                <SiteExportModal showSiteModal={showSiteModal} handleClose={handleClose}/>
            </Row>
            <Row>
                <Col sm={5}><SiteList selectedRow={selectedRow} screen={screen}/></Col>
                <Col sm={7}>
                    <div className="mb-sm-1 text-end">
                        <Button className="mx-sm-1 buttonCss" onClick={showModal}>Export</Button>
                        <Link to="/multiSite"><Button className="mx-sm-1 buttonCss">Multi Site Update</Button></Link>
                        <Link to="/plant"><Button className="mx-sm-1 buttonCss">Plant Master Data</Button></Link>
                        <Link to="/plantconfig"><Button className="mx-sm-1 buttonCss">Form Attributes</Button></Link>
                    </div>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation} formValidationMessage={messageData}/>
                    <SiteForm submitHandler={submitHandler} handleChange={handleChange}
                              cancelHandler={cancelHandler} isAdminAccess={isAdminAccess}
                              buttonTitle={buttonTitle}
                              plantData={plantData}
                              visitFrequencyList={visitFrequencyList}
                              traumaLevelList={traumaLevelList} idnList={idnList}
                              samList={samList} dvpList={dvpList} rdoList={rdoList} gmList={gmList}
                              formValue={formValue} formValidation={formValidation}
                              inputValidation={inputValidation}
                              response={response} error={error} loading={loading} showMessage={showMessage}
                              disableSubmit={disableSubmit} readOnlyField={readOnlyField}
                              handleIdnChange={handleIdnChange} innerIdnRef={innerIdnRef} idnNewList={idnNewList}
                              handleClick={handleClick}
                              handleSearch={handleSearch} handleCheckboxChange={handleCheckboxChange} flag={true}
                    /></Col>
            </Row>
        </Container>
    )
};

export default Site;
